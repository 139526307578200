#landing-container{
    height:90vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    padding: 10vw;
    font-family: "Poppins";
    font-weight: 600;
}

#logo{
   width:180px;
   height:180px;
}

#moving-logo {
  bottom: 0px;
  right: 0px;
  animation: slide-in 1s ease forwards, wave 4s infinite linear;
  opacity: 0;
  padding-top: 10vh;
}

@keyframes slide-in {
  from {
    transform: translateX(100px); /* Start position for the animation */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* End position for the animation */
    opacity: 1;
  }
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px); /* Middle position for the wave animation */
  }
  100% {
    transform: translateY(0); /* End position for the wave animation */
  }
}
