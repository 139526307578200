.team-container{
   height: 100%;
   color: white;
}
.team-container h1{
    font-size: 3.3rem !important;
    font-weight: 400 !important; 
    
}

.team-view{
    padding: 2rem;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    row-gap: 3rem;
    column-gap: 12px;
}

.image-grid p {
    width: 100%;
    height: auto;
}

#member-card{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#member-card h4{
    padding-top: 10px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    row-gap: 3rem;
    column-gap: 12px;
}

#board-headshot{
    max-width: 150px;
    border: 2px solid darkgrey;
    border-radius: 50%;
}


@media  screen and (min-width: 768px) {

    #board-headshot{
        max-width: 250px;
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        row-gap: 3rem;
        column-gap: 12px;
    }

    .team-view{
        padding-top: 2rem;
    }

    .team-view, .community, .marketing{
        padding-bottom: 5rem;
    }



}