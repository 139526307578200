.navbar {
    padding-left: 10px;
    padding-right: 10px;
    background-color: 5893A6;
  }

NavLink{
  color: red;
}

